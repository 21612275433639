@import url(https://fonts.googleapis.com/css?family=Ubuntu);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Rubik", sans-serif;
  color: #777777;
  padding-top: 72px;
  background: url(/static/media/body-bg.1bf1fd83.png) no-repeat center;
}

p {
  text-align: justify;
}

h2 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
}

.separator{
  margin-top: 2em;
  margin-bottom: 2em;
}

.log-title {
  display: block;
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 4px;
  color: #000000;
  text-transform: uppercase;
}

.date{
  font-size: 0.5em;
}

.container {
  width: 70%;
  min-width: 380px;
}

.caption {
  font-size: 0.8em;
}

.fixed-top {
  position:fixed;
  box-shadow: 0 3px 16px 0 rgba(0,0,0,0.1);
  top:0;
  left:0;
  right:0;
  width:100%;
  z-index:99;
  background:white;
}

.card {
  margin-top: 1em;
  margin-bottom: 1em;
}
.card:hover {
  box-shadow: 0 3px 16px 0 #6d63e7;
}

.navbar {
  display: block;
}

.btn-primary, .btn-primary:visited{
  background-color: #6d63e7;
  border-color: #6d63e7;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:checked{
  background-color: #4c45a2;
  border-color: #4c45a2;
  box-shadow: 0 0 0 0 ;
}
.btn-primary:disabled, .btn-primary.disabled {
  background-color: #4c45a2;
  border-color: #4c45a2;
}

.btn-primary:focus{
  box-shadow: 0 0 0 0 ;
}

.footer_area {
  padding-top: 32px !important;
  padding-bottom: 64px !important;
  text-align: center;
 }

.footer_area p {
  font-size: 14px;
  margin-top: 0.5em;
  text-align: inherit;
}
.footer_logo {
  padding: 2em 2em 1em;
}


.blank_link {
  color: #854fee;
}

.image_link{
  color: #212529;
}
.image_link:hover{
  color: #854fee;
}

.blank_link:hover {
  color: inherit;
}

.grid-container {
  -webkit-columns: 5 200px;
          columns: 5 200px;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
  width: 90%;
  margin: 0 auto;
}
.grid-container div {
  margin: 0 1.5rem 1.5rem 0;
  display: inline-block;
  width: 100%;
  border: solid 2px black;
  padding: 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: all 0.25s ease-in-out;
}
.grid-container div:hover img {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
.grid-container div:hover {
  border-color: coral;
}
.grid-container div img {
  width: 100%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  border-radius: 5px;
  transition: all 0.25s ease-in-out;
}
.grid-container div p {
  margin: 5px 0;
  padding: 0;
  text-align: center;
  font-style: italic;
}

* {
  box-sizing: border-box;
}
.footer_area::before{
    content: none;
}
.box {
  width: 350px;
  height: 100%;
  max-height: 600px;
  min-height: 450px;
  background: #6D63E7;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 30px 50px;
}
.box .box__ghost {
  padding: 15px 25px 25px;
  position: absolute;
  left: 50%;
  top: 30%;
  -webkit-transform: translate(-50%, -30%);
          transform: translate(-50%, -30%);
}
.box .box__ghost .symbol:nth-child(1) {
  opacity: .2;
  -webkit-animation: shine 4s ease-in-out 3s infinite;
          animation: shine 4s ease-in-out 3s infinite;
}
.box .box__ghost .symbol:nth-child(1):before, .box .box__ghost .symbol:nth-child(1):after {
  content: '';
  width: 12px;
  height: 4px;
  background: #fff;
  position: absolute;
  border-radius: 5px;
  bottom: 65px;
  left: 0;
}
.box .box__ghost .symbol:nth-child(1):before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.box .box__ghost .symbol:nth-child(1):after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.box .box__ghost .symbol:nth-child(2) {
  position: absolute;
  left: -5px;
  top: 30px;
  height: 18px;
  width: 18px;
  border: 4px solid;
  border-radius: 50%;
  border-color: #fff;
  opacity: .2;
  -webkit-animation: shine 4s ease-in-out 1.3s infinite;
          animation: shine 4s ease-in-out 1.3s infinite;
}
.box .box__ghost .symbol:nth-child(3) {
  opacity: .2;
  -webkit-animation: shine 3s ease-in-out .5s infinite;
          animation: shine 3s ease-in-out .5s infinite;
}
.box .box__ghost .symbol:nth-child(3):before, .box .box__ghost .symbol:nth-child(3):after {
  content: '';
  width: 12px;
  height: 4px;
  background: #fff;
  position: absolute;
  border-radius: 5px;
  top: 5px;
  left: 40px;
}
.box .box__ghost .symbol:nth-child(3):before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.box .box__ghost .symbol:nth-child(3):after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.box .box__ghost .symbol:nth-child(4) {
  opacity: .2;
  -webkit-animation: shine 6s ease-in-out 1.6s infinite;
          animation: shine 6s ease-in-out 1.6s infinite;
}
.box .box__ghost .symbol:nth-child(4):before, .box .box__ghost .symbol:nth-child(4):after {
  content: '';
  width: 15px;
  height: 4px;
  background: #fff;
  position: absolute;
  border-radius: 5px;
  top: 10px;
  right: 30px;
}
.box .box__ghost .symbol:nth-child(4):before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.box .box__ghost .symbol:nth-child(4):after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.box .box__ghost .symbol:nth-child(5) {
  position: absolute;
  right: 5px;
  top: 40px;
  height: 12px;
  width: 12px;
  border: 3px solid;
  border-radius: 50%;
  border-color: #fff;
  opacity: .2;
  -webkit-animation: shine 1.7s ease-in-out 7s infinite;
          animation: shine 1.7s ease-in-out 7s infinite;
}
.box .box__ghost .symbol:nth-child(6) {
  opacity: .2;
  -webkit-animation: shine 2s ease-in-out 6s infinite;
          animation: shine 2s ease-in-out 6s infinite;
}
.box .box__ghost .symbol:nth-child(6):before, .box .box__ghost .symbol:nth-child(6):after {
  content: '';
  width: 15px;
  height: 4px;
  background: #fff;
  position: absolute;
  border-radius: 5px;
  bottom: 65px;
  right: -5px;
}
.box .box__ghost .symbol:nth-child(6):before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.box .box__ghost .symbol:nth-child(6):after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.box .box__ghost .box__ghost-container {
  background: #fff;
  width: 100px;
  height: 100px;
  border-radius: 100px 100px 0 0;
  position: relative;
  margin: 0 auto;
  -webkit-animation: upndown 3s ease-in-out infinite;
          animation: upndown 3s ease-in-out infinite;
}
.box .box__ghost .box__ghost-container .box__ghost-eyes {
  position: absolute;
  left: 50%;
  top: 45%;
  height: 12px;
  width: 70px;
}
.box .box__ghost .box__ghost-container .box__ghost-eyes .box__eye-left {
  width: 12px;
  height: 12px;
  background: #332F63;
  border-radius: 50%;
  margin: 0 10px;
  position: absolute;
  left: 0;
}
.box .box__ghost .box__ghost-container .box__ghost-eyes .box__eye-right {
  width: 12px;
  height: 12px;
  background: #332F63;
  border-radius: 50%;
  margin: 0 10px;
  position: absolute;
  right: 0;
}
.box .box__ghost .box__ghost-container .box__ghost-bottom {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
.box .box__ghost .box__ghost-container .box__ghost-bottom div {
  flex-grow: 1;
  position: relative;
  top: -10px;
  height: 20px;
  border-radius: 100%;
  background-color: #fff;
}
.box .box__ghost .box__ghost-container .box__ghost-bottom div:nth-child(2n) {
  top: -12px;
  margin: 0 -0px;
  border-top: 15px solid #332F63;
  background: transparent;
}
.box .box__ghost .box__ghost-shadow {
  height: 20px;
  box-shadow: 0 50px 15px 5px #3B3769;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-animation: smallnbig 3s ease-in-out infinite;
          animation: smallnbig 3s ease-in-out infinite;
}
.box .box__description {
  position: absolute;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.box .box__description .box__description-container {
  color: #fff;
  text-align: center;
  width: 200px;
  font-size: 16px;
  margin: 0 auto;
}
.box .box__description .box__description-container .box__description-title {
  font-size: 24px;
  letter-spacing: .5px;
}
.box .box__description .box__description-container .box__description-text {
  color: #8C8AA7;
  line-height: 20px;
  margin-top: 20px;
}
.box .box__description .box__button {
  display: block;
  position: relative;
  background: #FF5E65;
  border: 1px solid transparent;
  border-radius: 50px;
  height: 50px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  line-height: 50px;
  font-size: 18px;
  padding: 0 70px;
  white-space: nowrap;
  margin-top: 25px;
  transition: background .5s ease;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.box .box__description .box__button:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 100px;
  background: #fff;
  bottom: -25px;
  left: 0;
  border: 2px solid #fff;
  -webkit-transform: translateX(-50px) rotate(45deg);
          transform: translateX(-50px) rotate(45deg);
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
}
.box .box__description .box__button:hover {
  background: transparent;
  border-color: #fff;
}
.box .box__description .box__button:hover:before {
  -webkit-transform: translateX(250px) rotate(45deg);
          transform: translateX(250px) rotate(45deg);
}

@-webkit-keyframes upndown {
  0% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

@keyframes upndown {
  0% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
  50% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
@-webkit-keyframes smallnbig {
  0% {
    width: 90px;
  }
  50% {
    width: 100px;
  }
  100% {
    width: 90px;
  }
}
@keyframes smallnbig {
  0% {
    width: 90px;
  }
  50% {
    width: 100px;
  }
  100% {
    width: 90px;
  }
}
@-webkit-keyframes shine {
  0% {
    opacity: .2;
  }
  25% {
    opacity: .1;
  }
  50% {
    opacity: .2;
  }
  100% {
    opacity: .2;
  }
}
@keyframes shine {
  0% {
    opacity: .2;
  }
  25% {
    opacity: .1;
  }
  50% {
    opacity: .2;
  }
  100% {
    opacity: .2;
  }
}
.banner_content{
    height: 800px;
    width:75%;
}
.banner_cover {
    position: relative;
    overflow: hidden;
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    min-height: 485px;
    background-color: #6D63E7;    
}
.box__title{
    color: white;
}
